.footer {
  display: flex;
  flex-direction: column;
  padding: .7em;
  text-align: center;
  background-color: var(--dark-blue);
}

.footer p {
  margin: .2em;
  font-size: .9em;
  color: white;
}