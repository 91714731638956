#home-logo-link {
  display: inline-block;
}

#nav-logo {
  height: 6vw;
  padding: .5em;
  vertical-align: top;
}

.header {
  position: sticky;
  top: 0;
  z-index: 150;
}

.desktop-container{
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 5px darkgrey;
  font-family: 'Lato', sans-serif;
  overflow: hidden;
}

.logo-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 1em;
}

.contact-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: var(--light-blue);
  color: white;
  font-weight: 400;
  font-size: 1.1em;
}

.contact-list a:hover {
  color: white;
  font-weight: bold;
}

.phone, .address {
  padding: .5em 1.2em;
}

.address {
  margin-left: 4em;
}

.phone:hover, .address:hover {
  color: white;
  font-weight: bold;
}

.portal-pay {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  background-color: rgb(243, 152, 33);
}

.portal-pay > li {
  padding: .5em 1.2em;
}

.portal {
  border-left: solid 1px white;
  border-right: solid 1px white;
}

.payment-button {
  padding: 0;
  border: none;
  background-color: rgb(243, 152, 33);
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 1em;
}

.payment-button:hover {
  cursor: pointer;
  font-weight: bold;
}

.nav-list {
  margin: 0;
  padding: .2em 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-list li {
  margin: 0 .5em;
  padding: .4em 0;
  text-align: center;
  font-size: .8em;
  font-weight: 400;
  color:rgb(80, 79, 79);
}

.navbar-mobile-container {
  display: none;
}

.navbar-mobile-container ul li a:hover {
  color: black;
}

.mobile-logo-wrapper, .mobile-logo{
  display: none;
}



@media screen and (max-width: 881px) {
  .header {
    position: static;
  }

  .logo-nav {
    display: none;
  }

  .phone, .address {
    font-size: 1.1em;
    width: 100%;
  }
  
  .phone {
    width: 100%;
    background-color: var(--dark-blue);
    color: white;
    padding: .5em 0;
  }

  .address {
    margin: 0;
    padding: .8em 0;
    font-size: 1em;
    background-color: var(--light-blue);
    width: 100%;
  }

  .contact-list {
    display: flex;
    flex-direction: column;
    background-color: white;
  }

  .portal-pay {
    width: 100%;
    display: flex;
  }

  .portal-pay > li {
    width: 50%;
    text-align: center;
    padding: .3em .5em;
    font-size: 1.2em;
  }

  .portal {
    border-left: none;
  }

  .navbar-mobile-container {
    position: sticky;
    top: 0;
    width: 100%;
    height: 42px;
    display: block;
    text-align: center;
    z-index: 101;
    background-color: white;
    box-shadow: 0 5px 5px -5px darkgrey;
  }

  .navbar-mobile-title {
    display: block;
    font-size: 1.2em;
    color: var(--dark-blue);
    padding: .6em 0;
  }

  .navbar-mobile-title:hover {
    cursor: pointer;
  }

  .nav-visible {
    visibility: visible;
    height: 300px;
    opacity: 1;
    transition: all ease-out .35s;
    overflow: hidden;
  }

  .nav-hidden {
    visibility: hidden;
    height: 0px;
    opacity: 0;
    transition: all ease-out .35s;
    overflow: hidden;
  }

  #navbar-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;
    font-size: 1em;
    list-style: none;
    background-color: var(--light-blue);
    color: white;
  }

  #navbar-mobile li {
    font-size: 1.2em;
  }

  .header-contact-mobile {
    display: block;
    padding: 0;
    text-align: center;
    font-size: 1.1em;
    line-height: 1.5em;
  }

  .header-phone-mobile a {
    padding-bottom: 5px;
    font-size: 1.3em;
    border-bottom: solid 1px var(--dark-blue);
    color: var(--light-blue);
  }

  .header-contact-mobile address {
    font-style: normal;
    padding-top: 12px;
    font-size: .9em;
  }

  .nav-pay-portal-mobile {
    margin-top: .5em;
    display: flex;
    justify-content: space-around;
    font-size: 1.1em;
    background-color: var(--light-blue);
    color: white;
  }

  .header-btn-mobile {
    padding: .4em 0;
    width: 100%;
  }

  .header-btn-mobile:nth-child(odd) {
    border-right: solid 1px white;
  }

  .nav-active {
    color: var(--dark-blue);
  }

  .mobile-logo {
    margin: 1.5em auto;
    display: block;
    height: 100px;
    z-index: 200;
  }
}