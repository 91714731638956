.home-contact {
    padding: 2em 6em;
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-items: flex-start;
    align-items: center;
    background-color: #F8F9FA;
    color: rgb(73, 71, 71);
}

/* PSEUDO ELEMENTS */
.home-contact h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.5em;
    font-weight: 700;
}

.home-contact p {
    font-weight: 400;
    font-size: 1.1em;
}

.home-contact svg {
    height: 22px;
    width: 25px;
    margin-right: 5px;
    vertical-align: middle;
}

.home-contact a:hover {
    color: var(--light-blue);
}

/* TITLE */
.contact-title {
    grid-column: 1/3;
    grid-row: 1/2;
    margin-top: 0;
    font-weight: 700;
    font-size: 2.1em;
    justify-self: center;
    position: relative;
    color: var(--dark-blue);
}

.contact-title::after {
    position: absolute;
    content: "";
    height: 2px;
    width: 60%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: -5px auto;
    text-align: center;
    background-color: #F39820;
}

/* HOURS */
.contact-hours {
    grid-row: 2/3;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: min-content;
    align-content: center;
    height: 100%;
    line-height: 2.2em;
}

.hours-title {
    grid-column: 1/3;
}

.days-list, .time-list {
    list-style: none;
    margin: 1em 0 0 0;
    padding: 0;
    font-size: 1.1em;
    font-weight: 400;
}

.time-list {
    text-align: right;
}

.closed {
    text-align: center;
}

/* PHONE AND ADDRESS */
.contact-phone-address {
    grid-row: 2/3;
}

.contact-phone-address p {
    margin: .5em 0;
}


/* MAP */
.contact-map-wrapper {
    grid-column: 3/4;
    grid-row: 1/3;
    width: 100%;
    height: 100%;
}

#home-map {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}


@media screen and (max-width: 1090px) {
    .home-contact {
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
        gap: 1em;
        padding: 1em;
    }

    .contact-title {
        grid-row: 1/2;
        grid-column: 1/3;
    }

    .contact-hours {
        grid-row: 2/3;
        grid-column: 1/2;
    }

    .contact-phone-address {
        grid-row: 2/3;
        grid-column: 2/3;
    }

    .contact-map-wrapper {
        grid-row: 3/4;
        grid-column: 1/3;
        height: 350px;
        width: 350px;
    }

    #home-map {
        height: 350px;
        width: 350px;
    }
}

@media screen and (max-width: 752px) {
    .home-contact {
        display: flex;
        flex-direction: column;
    }

    .home-contact h3 {
        text-align: center;
    }

    .contact-phone-address {
        order: 1;
    }

    .contact-hours {
        order: 2;
        height: auto;
    }

    .contact-map-wrapper {
        order: 3;
        margin-top: 1em;
    }
}