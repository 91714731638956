
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');


:root {
  --light-blue: #00a7e2;
  --dark-blue: #1b3360;
}

#main {
  font-family: 'Open Sans', sans-serif;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: var(--light-blue);
}

h2 {
  font-size: 2.3em;
  font-weight: 600;
  line-height: 1.4em;
}

h3 {
  font-size: 1.7em;
  font-weight: 600;
  line-height: 1.4em;
}

address {
  font-style: normal;
}

p, ul {
  font-size: 1.2em;
  font-weight: 400;
  color: rgb(73, 71, 71);
}

/* Subpage Template */
.subpage {
  padding: 0 8em;
  background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), 100%/cover url("../assets/background/wood-fade.jpg");
}

.subpage-content {
  min-height: 100vh;
  padding: 1em 4em 1em 4em;
  background-color: white;
}

.subpage-content header {
  text-align: center;
  margin-bottom: 2em;
}

.subpage-content h1 {
  font-size: 2.6em;
  margin: 0;
  padding: .5em 0;
  text-align: center;
  font-weight: 300;
  color: var(--dark-blue);
}

.subpage-content h2 {
  margin: 0 0 1em 0;
  font-weight: 400;
  font-size: 2.6em;
  color: var(--light-blue);
}

.subpage-content h3 {
  margin: 0;
  padding: 1em 0;
  font-weight: 300;
  font-size: 2em;
}

.subpage-content p, ul {
  font-weight: 400;
  font-size: 1.3em;
  line-height: 1.5em;
}

.cursive {
  font-family: 'Dancing Script', cursive;
}


@media screen and (min-width: 895px) {

  .subpage-content h1 {
    display: flex;
  }

  .subpage-content h1::before,
  .subpage-content h1::after{
  content:'';
  flex: 1 1;
  border-bottom: 2px solid darkgrey;
  margin: auto;
}

}

@media screen and (max-width: 1130px) {

  html {
    font-size: 90%;
  }
}

@media screen and (max-width: 895px) {
 
  .subpage {
    padding: 0;
  }

  .subpage-content {
    padding: 1em;
  }

  .subpage-content h1 {
    font-size: 2.5em;
  }

  .subpage-content h2 {
    text-align: center;
  }
}

/* Animations */
@keyframes pop-in {
  0% {
      transform: scale(.99);
      opacity: 0;
  }
  100% {
      transform: scale(1);
      opacity: 1;
  }
}