#dr-callis-headshot {
    width: 30vw;
    object-fit: cover;
    box-shadow: -25px 25px 1px var(--dark-blue);
}

#abim-logo {
    height: 110px;
}

.home-dr-callis {
    padding: 8em 6em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
}

.meet {
    color: #F39820;
}

.home-dr-callis h2 {
    margin-top: 0;
    font-weight: 400;
    font-size: 3.3em;
    color: var(--light-blue);
}

@media screen and (max-width: 900px) {
    .home-dr-callis {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2em;
        text-align: center;
    }

    #dr-callis-headshot {
        width: 60vw;
        margin: 4em 0;
    }

    .home-dr-callis p {
        font-size: 1.4em;
    }
}