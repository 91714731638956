.home-services {
  padding: 2em 6em 6em 6em;
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), 100%/cover url("../../../assets/background/wood-fade.jpg");  
}

.services-title h2 {
  font-weight: 400;
  font-size: 2.4em;
  color: var(--light-blue);
  text-align: center;
}

.services-title h3 {
  text-align: center;
  font-weight: 200;
  font-size: 2em;
  color: rgb(73, 71, 71);
}

.services-tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.tile-box {
  position: relative;
  margin: 2em .5em;
  overflow: hidden;
  border-radius: 2px;
  box-shadow: 0 0 3px rgb(99, 96, 96);
}

.tile-box:hover .services-tile {
  opacity: 0;
  transition: .25s;
}

.tile-inside {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 1em;
  text-align: center;
  opacity: 0;
  color:rgb(99, 96, 96);
  background-color: white;
  font-size: 1.1em;
  transform: scale3d(2, 2, 1);
  transition: transform .25s, opacity .15s;
}

.tile-box:hover .tile-inside {
  opacity: 1;
  transform: scale3d(1, 1, 1);
  transition: all .25s;
}


.services-tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 420px;
  width: 290px;
  text-align: center;
  background-color: white;
}

.services-tile svg {
  height: 17%;
  fill: #F39822;
  padding-bottom: 1em;
}

.services-tile h3 {
  font-weight: normal;
  font-size: 1.4em;
  font-weight: 700;
  color: rgb(99, 96, 96);
}


@media screen and (max-width: 870px) {
  .home-services {
    margin-top: 1em;
    padding: 1em 1em 4em 1em;
  }

  .tile-box {
    margin-bottom: 1em;
  }

  .services-tile {
    height: 350px;
    width: 290px;
  }

  .services-tile svg {
    height: 25%;
  }
}