#otoscope {
    width: 30%;
    float: right;
    margin: 1em;
    border-radius: 5px;
    animation: pop-in 1s;
}

.disease-list {
    font-size: .8rem;
    h3 {
        padding-bottom: 0 !important;
    }
    ul {
        overflow: scroll;
    }
}

.bring-form-btn {
    display: flex;
    width: 320px;
    height: 50px;
    margin: 2em auto;
    justify-content: center;
    align-items: center;
    background-color: var(--light-blue);
    color: white;
    font-size: 1.2em;
    transition: box-shadow .25s;
}

.bring-form-btn:hover {
    box-shadow: 4px 4px var(--dark-blue);
    color: white;
    transition: .25s;
}