.home-forms {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 1em;
    background:  linear-gradient(rgba(27,51,96, 0.75), rgba(27,51,96, 0.75)), url("../../../assets/office-photos/exterior-wide-edit.jpg") no-repeat;
    background-attachment: fixed;
    background-position: 47.5% center;

    color: white;
}

.home-forms h2 {
    margin: .6em 0;
    padding: 0 .3em;
    font-size: 1.7em;
    font-weight: 400;
}

.home-forms-btn {
    margin: 1em;
    display: flex;
    width: 320px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border: solid 1px white;
    font-size: 1.2em;
}

.home-forms-btn:hover {
    background-color: white;
    color: var(--dark-blue);
    transition: all .2s;
}

/* iOS Background Fix */
@supports (-webkit-overflow-scrolling: touch) {
    .home-forms {
    background-attachment: scroll;
    background-position: 47.5% 70%;
    }
  }

@media screen and (max-width: 870px) { 

    .home-forms h2 {
        text-align: center;
    }

    .home-forms-btn-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .home-forms-btn {
        margin: .5em;
        width: 300px;
        background-color: rgba(255, 255, 255, .2)
    }
}