.workspace {
  height: 600px;
  background: linear-gradient(rgba(85, 84, 84, 0.15), rgba(85, 84, 84, 0.15)), url("../../../assets/background/kentucky-background.jpg");
  background-position: 100% 15%;
  background-size: cover;
  font-family: 'Open Sans', sans-serif;
}

.banner-title-wrapper {
  position: relative;
  margin-left: 3em;
  padding-top: 6em;
  width: auto;
  animation: fade-in 1.2s;
}

.pre-title {
  display: inline-block;
  bottom: 0;
  left: 0;
  font-size: 2.8em;
  font-weight: 700;
  color: white;
}

.control {
  font-weight: 700;
}

.slide {
    position: absolute;
    left: 2px;
    opacity: 0;
    font-size: 2.8em;
    font-weight: 700;
    color: white;
  }
  
  .slide.showing {
    opacity: 1;
    margin: 0;
    bottom: -25px;
    transition: all 1s;
  }

  #joint-pain {
    left: 8px;
  }

  #articular {
    bottom: -87px;
  }

  @media screen and (max-width: 950px) {
    .workspace {
      display: none;
    }

    .banner-title-wrapper {
      width: 100%;
      margin: 1em;
      padding: 0;
    }

    #articular {
      bottom: -73px;
    }
  }

  @media screen and (max-width: 720px) {
    .workspace {
      background-position: 68%;
    }

    .banner-title-wrapper {
      display: none;
    }
  }


  @keyframes fade-in {
    0% {
      opacity: 0
    }
    100% {
      opacity: 1;
    }
  }