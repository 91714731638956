.medications {
    background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), 100%/300% url("../../assets/background/wood-fade.jpg") !important;
}

.medications .alphabet {
    display: flex;
    justify-content: space-evenly;
    padding: 1em 4em 2em 4em;
    color: var(--light-blue);
    font-weight: 400;
    font-size: 1.4em;
}

.letter-list li {
    font-size: .8em;
}

.faded {
    font-weight: 200;
    color: grey;
}

.letter-container {
    display: grid;
    grid-template-columns: .5fr 1fr;
    margin: 2em 0;
}

.letter-container h2 {
    text-align: center;
    font-size: 3em !important;
}

.letter-list {
    list-style: none;
    font-size: 1.2em;
    color: rgb(73, 71, 71);
    font-weight: bold;
}

.letter-list ul {
    list-style: none;
    margin: 1em 0;
    padding: 0;
    font-weight: normal;
}

.arrow-wrapper {
    position: fixed;
    bottom: 70px;
    right: 170px;
    transform: scale(0);
    transition: transform .4s;
}

.arrow-wrapper-scrolled {
    position: fixed;
    bottom: 70px;
    right: 170px;
    cursor: pointer;
    transform: scale(1);
    transition: transform .2s;
}

#up-arrow-icon {
    height: 60px;
    width: 60px;
    transform: rotate(-90deg);
}

@media screen and (max-width: 775px) {
    .mobile-layout {
        display: flex;
    }

    .medications .alphabet {
        flex-direction: column;
        padding: 0 .1em;
        position: sticky;
        top: 45px;
        width: min-content;
        height: 650px;
        font-size: .8em;
    }

    .letter-container {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .letter-list {
        padding: 0;
    }

    .arrow-wrapper {
        bottom: 35px;
        right: 15px;
    }

    .arrow-wrapper-scrolled {
        bottom: 35px;
        right: 15px;
    }

    #up-arrow-icon {
        height: 50px;
        width: 50px;
    }
}