#laughing {
    width: 30%;
    float: right;
    margin: 1em;
    border-radius: 5px;
    animation: pop-in 1s;
}

@media screen and (max-width: 775px) {
    #laughing {
        float: none;
        width: 75%;
        display: block;
        margin: 0 auto;
    }
}