#calling-icon {
    width: 350px
}


.home-welcome {
    padding:  3em 6em 4em 6em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    align-items: center;
    justify-items: center;
    font-family: 'Open Sans', sans-serif;
}

.welcome-article {
    line-height: 2em;
}

.home-welcome h2 {
    font-size: 2em;
    font-weight: 600;
    line-height: 1.4em;
    color: var(--dark-blue);
}

.welcome-article p {
    font-weight: 400;
    font-size: 1.2em;
}

.welcome-appt-container {
    justify-self: flex-end;
}


.welcome-appt {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 2em;
}

.schedule {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50px;
    width: 300px;
    font-weight: 400;
    font-size: 1.3em;
    text-align: center;
    background-color: var(--light-blue);
    color: white;
    box-shadow: none;
    transition: box-shadow .25s;
}

.schedule:hover {
    box-shadow: 4px 4px var(--dark-blue);
    transition: .25s;
}

@media screen and (max-width: 870px) {
    #calling-icon {
        width: 280px;
    }

    .home-welcome {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .welcome-appt {
        padding: 0;
        display: block;
    }
}

@media screen and (max-width: 540px) {
    .home-welcome {
        padding: 2em;
    }
}