@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#dr-callis {
    width: 30%;
    float: right;
    margin: 1em;
    border-radius: 5px;
    -webkit-animation: pop-in 1s;
            animation: pop-in 1s;
}


@media screen and (max-width: 775px) {
    #dr-callis {
        float: none;
        width: 75%;
        display: block;
        margin: 0 auto;
    }
}

.footer {
  display: flex;
  flex-direction: column;
  padding: .7em;
  text-align: center;
  background-color: var(--dark-blue);
}

.footer p {
  margin: .2em;
  font-size: .9em;
  color: white;
}
#calling-icon {
    width: 350px
}


.home-welcome {
    padding:  3em 6em 4em 6em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    gap: 1em;
    align-items: center;
    justify-items: center;
    font-family: 'Open Sans', sans-serif;
}

.welcome-article {
    line-height: 2em;
}

.home-welcome h2 {
    font-size: 2em;
    font-weight: 600;
    line-height: 1.4em;
    color: var(--dark-blue);
}

.welcome-article p {
    font-weight: 400;
    font-size: 1.2em;
}

.welcome-appt-container {
    justify-self: flex-end;
}


.welcome-appt {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 2em;
}

.schedule {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50px;
    width: 300px;
    font-weight: 400;
    font-size: 1.3em;
    text-align: center;
    background-color: var(--light-blue);
    color: white;
    box-shadow: none;
    transition: box-shadow .25s;
}

.schedule:hover {
    box-shadow: 4px 4px var(--dark-blue);
    transition: .25s;
}

@media screen and (max-width: 870px) {
    #calling-icon {
        width: 280px;
    }

    .home-welcome {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .welcome-appt {
        padding: 0;
        display: block;
    }
}

@media screen and (max-width: 540px) {
    .home-welcome {
        padding: 2em;
    }
}
.home-services {
  padding: 2em 6em 6em 6em;
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), 100%/cover url(/static/media/wood-fade.47e01974.jpg);  
}

.services-title h2 {
  font-weight: 400;
  font-size: 2.4em;
  color: var(--light-blue);
  text-align: center;
}

.services-title h3 {
  text-align: center;
  font-weight: 200;
  font-size: 2em;
  color: rgb(73, 71, 71);
}

.services-tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.tile-box {
  position: relative;
  margin: 2em .5em;
  overflow: hidden;
  border-radius: 2px;
  box-shadow: 0 0 3px rgb(99, 96, 96);
}

.tile-box:hover .services-tile {
  opacity: 0;
  transition: .25s;
}

.tile-inside {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 1em;
  text-align: center;
  opacity: 0;
  color:rgb(99, 96, 96);
  background-color: white;
  font-size: 1.1em;
  transform: scale3d(2, 2, 1);
  transition: transform .25s, opacity .15s;
}

.tile-box:hover .tile-inside {
  opacity: 1;
  transform: scale3d(1, 1, 1);
  transition: all .25s;
}


.services-tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 420px;
  width: 290px;
  text-align: center;
  background-color: white;
}

.services-tile svg {
  height: 17%;
  fill: #F39822;
  padding-bottom: 1em;
}

.services-tile h3 {
  font-weight: normal;
  font-size: 1.4em;
  font-weight: 700;
  color: rgb(99, 96, 96);
}


@media screen and (max-width: 870px) {
  .home-services {
    margin-top: 1em;
    padding: 1em 1em 4em 1em;
  }

  .tile-box {
    margin-bottom: 1em;
  }

  .services-tile {
    height: 350px;
    width: 290px;
  }

  .services-tile svg {
    height: 25%;
  }
}
.home-office-photos {
    padding: 6em 10em;
}

.photos-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 250px);
    grid-template-areas: 
        "g i a"
        "g h f"
        "b e f"
    ;
    grid-gap: 8px;
    gap: 8px;
}

#exam-room-wall {
    grid-area: a;
}

#infusion-room {
    grid-area: b;
}

#exam-room {
    grid-area: e;
    object-position: 20%;
}
#infusion-chairs-2 {
    grid-area: f;
    object-position: 95%;
}
#hallway {
    grid-area: g
}

#exterior-logo {
    grid-area: h
}

#lobby {
    grid-area: i
}

.photos-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
}

@media screen and (max-width: 900px) {
    .home-office-photos {
        padding: 1em;
    }

    .photos-container {
        display: flex;
        flex-direction: column;
    }

    .photos-container img {
        margin-top: .5em;
    }
}
#dr-callis-headshot {
    width: 30vw;
    object-fit: cover;
    box-shadow: -25px 25px 1px var(--dark-blue);
}

#abim-logo {
    height: 110px;
}

.home-dr-callis {
    padding: 8em 6em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
}

.meet {
    color: #F39820;
}

.home-dr-callis h2 {
    margin-top: 0;
    font-weight: 400;
    font-size: 3.3em;
    color: var(--light-blue);
}

@media screen and (max-width: 900px) {
    .home-dr-callis {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2em;
        text-align: center;
    }

    #dr-callis-headshot {
        width: 60vw;
        margin: 4em 0;
    }

    .home-dr-callis p {
        font-size: 1.4em;
    }
}
#research-icon {
    width: 80%;
    height: 85%;
    transform: scale(-1, 1);
}

.home-quest {
    padding: 2em 8em 4em 6em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #F8F9FA;  
}

.quest-img-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.quest-text {
    display: flex;
    flex-direction: column;
    color: rgb(73, 71, 71);
}

.quest-title {
    margin: .7em 0;
    color: var(--dark-blue);
    text-align: center;
}

.quest-days, .quest-hours {
    margin: .7em 0;
    font-weight: 400;
    font-size: 1.3em;
    text-align: center;
    
}

.quest-description {
    text-align: center;
}

@media screen and (max-width: 914px) {
    .home-quest {
        display: flex;
        flex-direction: column;
        padding: 1em;
    }

    .quest-description {
        padding: 0 6em;
        font-size: 1.3em;
    }

    .quest-img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1em 0; 
    }

    #research-icon {
        margin: 1em 0;
        width: 40vw;
        transform: scale(-1, 1);
    }
}

@media screen and (max-width: 600px) {
    .quest-days, .quest-hours {
        font-size: 1.4em;
    }

    .quest-days {
        font-size: 1.2em;
    }

    .quest-description {
        padding: 0 2em;
    }
}
.home-forms {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 1em;
    background:  linear-gradient(rgba(27,51,96, 0.75), rgba(27,51,96, 0.75)), url(/static/media/exterior-wide-edit.3a60d538.jpg) no-repeat;
    background-attachment: fixed;
    background-position: 47.5% center;

    color: white;
}

.home-forms h2 {
    margin: .6em 0;
    padding: 0 .3em;
    font-size: 1.7em;
    font-weight: 400;
}

.home-forms-btn {
    margin: 1em;
    display: flex;
    width: 320px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border: solid 1px white;
    font-size: 1.2em;
}

.home-forms-btn:hover {
    background-color: white;
    color: var(--dark-blue);
    transition: all .2s;
}

/* iOS Background Fix */
@supports (-webkit-overflow-scrolling: touch) {
    .home-forms {
    background-attachment: scroll;
    background-position: 47.5% 70%;
    }
  }

@media screen and (max-width: 870px) { 

    .home-forms h2 {
        text-align: center;
    }

    .home-forms-btn-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .home-forms-btn {
        margin: .5em;
        width: 300px;
        background-color: rgba(255, 255, 255, .2)
    }
}
.home-contact {
    padding: 2em 6em;
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-items: flex-start;
    align-items: center;
    background-color: #F8F9FA;
    color: rgb(73, 71, 71);
}

/* PSEUDO ELEMENTS */
.home-contact h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.5em;
    font-weight: 700;
}

.home-contact p {
    font-weight: 400;
    font-size: 1.1em;
}

.home-contact svg {
    height: 22px;
    width: 25px;
    margin-right: 5px;
    vertical-align: middle;
}

.home-contact a:hover {
    color: var(--light-blue);
}

/* TITLE */
.contact-title {
    grid-column: 1/3;
    grid-row: 1/2;
    margin-top: 0;
    font-weight: 700;
    font-size: 2.1em;
    justify-self: center;
    position: relative;
    color: var(--dark-blue);
}

.contact-title::after {
    position: absolute;
    content: "";
    height: 2px;
    width: 60%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: -5px auto;
    text-align: center;
    background-color: #F39820;
}

/* HOURS */
.contact-hours {
    grid-row: 2/3;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
    align-content: center;
    height: 100%;
    line-height: 2.2em;
}

.hours-title {
    grid-column: 1/3;
}

.days-list, .time-list {
    list-style: none;
    margin: 1em 0 0 0;
    padding: 0;
    font-size: 1.1em;
    font-weight: 400;
}

.time-list {
    text-align: right;
}

.closed {
    text-align: center;
}

/* PHONE AND ADDRESS */
.contact-phone-address {
    grid-row: 2/3;
}

.contact-phone-address p {
    margin: .5em 0;
}


/* MAP */
.contact-map-wrapper {
    grid-column: 3/4;
    grid-row: 1/3;
    width: 100%;
    height: 100%;
}

#home-map {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}


@media screen and (max-width: 1090px) {
    .home-contact {
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
        grid-gap: 1em;
        gap: 1em;
        padding: 1em;
    }

    .contact-title {
        grid-row: 1/2;
        grid-column: 1/3;
    }

    .contact-hours {
        grid-row: 2/3;
        grid-column: 1/2;
    }

    .contact-phone-address {
        grid-row: 2/3;
        grid-column: 2/3;
    }

    .contact-map-wrapper {
        grid-row: 3/4;
        grid-column: 1/3;
        height: 350px;
        width: 350px;
    }

    #home-map {
        height: 350px;
        width: 350px;
    }
}

@media screen and (max-width: 752px) {
    .home-contact {
        display: flex;
        flex-direction: column;
    }

    .home-contact h3 {
        text-align: center;
    }

    .contact-phone-address {
        order: 1;
    }

    .contact-hours {
        order: 2;
        height: auto;
    }

    .contact-map-wrapper {
        order: 3;
        margin-top: 1em;
    }
}
.workspace {
  height: 600px;
  background: linear-gradient(rgba(85, 84, 84, 0.15), rgba(85, 84, 84, 0.15)), url(/static/media/kentucky-background.ca99d022.jpg);
  background-position: 100% 15%;
  background-size: cover;
  font-family: 'Open Sans', sans-serif;
}

.banner-title-wrapper {
  position: relative;
  margin-left: 3em;
  padding-top: 6em;
  width: auto;
  -webkit-animation: fade-in 1.2s;
          animation: fade-in 1.2s;
}

.pre-title {
  display: inline-block;
  bottom: 0;
  left: 0;
  font-size: 2.8em;
  font-weight: 700;
  color: white;
}

.control {
  font-weight: 700;
}

.slide {
    position: absolute;
    left: 2px;
    opacity: 0;
    font-size: 2.8em;
    font-weight: 700;
    color: white;
  }
  
  .slide.showing {
    opacity: 1;
    margin: 0;
    bottom: -25px;
    transition: all 1s;
  }

  #joint-pain {
    left: 8px;
  }

  #articular {
    bottom: -87px;
  }

  @media screen and (max-width: 950px) {
    .workspace {
      display: none;
    }

    .banner-title-wrapper {
      width: 100%;
      margin: 1em;
      padding: 0;
    }

    #articular {
      bottom: -73px;
    }
  }

  @media screen and (max-width: 720px) {
    .workspace {
      background-position: 68%;
    }

    .banner-title-wrapper {
      display: none;
    }
  }


  @-webkit-keyframes fade-in {
    0% {
      opacity: 0
    }
    100% {
      opacity: 1;
    }
  }


  @keyframes fade-in {
    0% {
      opacity: 0
    }
    100% {
      opacity: 1;
    }
  }
#home-logo-link {
  display: inline-block;
}

#nav-logo {
  height: 6vw;
  padding: .5em;
  vertical-align: top;
}

.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 150;
}

.desktop-container{
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 5px darkgrey;
  font-family: 'Lato', sans-serif;
  overflow: hidden;
}

.logo-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 1em;
}

.contact-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: var(--light-blue);
  color: white;
  font-weight: 400;
  font-size: 1.1em;
}

.contact-list a:hover {
  color: white;
  font-weight: bold;
}

.phone, .address {
  padding: .5em 1.2em;
}

.address {
  margin-left: 4em;
}

.phone:hover, .address:hover {
  color: white;
  font-weight: bold;
}

.portal-pay {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  background-color: rgb(243, 152, 33);
}

.portal-pay > li {
  padding: .5em 1.2em;
}

.portal {
  border-left: solid 1px white;
  border-right: solid 1px white;
}

.payment-button {
  padding: 0;
  border: none;
  background-color: rgb(243, 152, 33);
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 1em;
}

.payment-button:hover {
  cursor: pointer;
  font-weight: bold;
}

.nav-list {
  margin: 0;
  padding: .2em 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-list li {
  margin: 0 .5em;
  padding: .4em 0;
  text-align: center;
  font-size: .8em;
  font-weight: 400;
  color:rgb(80, 79, 79);
}

.navbar-mobile-container {
  display: none;
}

.navbar-mobile-container ul li a:hover {
  color: black;
}

.mobile-logo-wrapper, .mobile-logo{
  display: none;
}



@media screen and (max-width: 881px) {
  .header {
    position: static;
  }

  .logo-nav {
    display: none;
  }

  .phone, .address {
    font-size: 1.1em;
    width: 100%;
  }
  
  .phone {
    width: 100%;
    background-color: var(--dark-blue);
    color: white;
    padding: .5em 0;
  }

  .address {
    margin: 0;
    padding: .8em 0;
    font-size: 1em;
    background-color: var(--light-blue);
    width: 100%;
  }

  .contact-list {
    display: flex;
    flex-direction: column;
    background-color: white;
  }

  .portal-pay {
    width: 100%;
    display: flex;
  }

  .portal-pay > li {
    width: 50%;
    text-align: center;
    padding: .3em .5em;
    font-size: 1.2em;
  }

  .portal {
    border-left: none;
  }

  .navbar-mobile-container {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    height: 42px;
    display: block;
    text-align: center;
    z-index: 101;
    background-color: white;
    box-shadow: 0 5px 5px -5px darkgrey;
  }

  .navbar-mobile-title {
    display: block;
    font-size: 1.2em;
    color: var(--dark-blue);
    padding: .6em 0;
  }

  .navbar-mobile-title:hover {
    cursor: pointer;
  }

  .nav-visible {
    visibility: visible;
    height: 300px;
    opacity: 1;
    transition: all ease-out .35s;
    overflow: hidden;
  }

  .nav-hidden {
    visibility: hidden;
    height: 0px;
    opacity: 0;
    transition: all ease-out .35s;
    overflow: hidden;
  }

  #navbar-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;
    font-size: 1em;
    list-style: none;
    background-color: var(--light-blue);
    color: white;
  }

  #navbar-mobile li {
    font-size: 1.2em;
  }

  .header-contact-mobile {
    display: block;
    padding: 0;
    text-align: center;
    font-size: 1.1em;
    line-height: 1.5em;
  }

  .header-phone-mobile a {
    padding-bottom: 5px;
    font-size: 1.3em;
    border-bottom: solid 1px var(--dark-blue);
    color: var(--light-blue);
  }

  .header-contact-mobile address {
    font-style: normal;
    padding-top: 12px;
    font-size: .9em;
  }

  .nav-pay-portal-mobile {
    margin-top: .5em;
    display: flex;
    justify-content: space-around;
    font-size: 1.1em;
    background-color: var(--light-blue);
    color: white;
  }

  .header-btn-mobile {
    padding: .4em 0;
    width: 100%;
  }

  .header-btn-mobile:nth-child(odd) {
    border-right: solid 1px white;
  }

  .nav-active {
    color: var(--dark-blue);
  }

  .mobile-logo {
    margin: 1.5em auto;
    display: block;
    height: 100px;
    z-index: 200;
  }
}
#stethoscope-heart {
    width: 30%;
    float: right;
    margin: 1em;
    border-radius: 5px;
    -webkit-animation: pop-in 1s;
            animation: pop-in 1s;
}

@media screen and (max-width: 775px) {
    #stethoscope-heart {
        float: none;
        width: 75%;
        display: block;
        margin: 0 auto;
    }
}
#couple-gaze {
    width: 30%;
    float: right;
    margin: 1em;
    border-radius: 5px;
    -webkit-animation: pop-in 1s;
            animation: pop-in 1s;
}

@media screen and (max-width: 775px) {
    #couple-gaze {
        float: none;
        width: 75%;
        display: block;
        margin: 0 auto;
    }
}
#laughing {
    width: 30%;
    float: right;
    margin: 1em;
    border-radius: 5px;
    -webkit-animation: pop-in 1s;
            animation: pop-in 1s;
}

@media screen and (max-width: 775px) {
    #laughing {
        float: none;
        width: 75%;
        display: block;
        margin: 0 auto;
    }
}
#otoscope {
    width: 30%;
    float: right;
    margin: 1em;
    border-radius: 5px;
    -webkit-animation: pop-in 1s;
            animation: pop-in 1s;
}

.disease-list {
    font-size: .8rem;
    h3 {
        padding-bottom: 0 !important;
    }
    ul {
        overflow: scroll;
    }
}

.bring-form-btn {
    display: flex;
    width: 320px;
    height: 50px;
    margin: 2em auto;
    justify-content: center;
    align-items: center;
    background-color: var(--light-blue);
    color: white;
    font-size: 1.2em;
    transition: box-shadow .25s;
}

.bring-form-btn:hover {
    box-shadow: 4px 4px var(--dark-blue);
    color: white;
    transition: .25s;
}
.medications {
    background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), 100%/300% url(/static/media/wood-fade.47e01974.jpg) !important;
}

.medications .alphabet {
    display: flex;
    justify-content: space-evenly;
    padding: 1em 4em 2em 4em;
    color: var(--light-blue);
    font-weight: 400;
    font-size: 1.4em;
}

.letter-list li {
    font-size: .8em;
}

.faded {
    font-weight: 200;
    color: grey;
}

.letter-container {
    display: grid;
    grid-template-columns: .5fr 1fr;
    margin: 2em 0;
}

.letter-container h2 {
    text-align: center;
    font-size: 3em !important;
}

.letter-list {
    list-style: none;
    font-size: 1.2em;
    color: rgb(73, 71, 71);
    font-weight: bold;
}

.letter-list ul {
    list-style: none;
    margin: 1em 0;
    padding: 0;
    font-weight: normal;
}

.arrow-wrapper {
    position: fixed;
    bottom: 70px;
    right: 170px;
    transform: scale(0);
    transition: transform .4s;
}

.arrow-wrapper-scrolled {
    position: fixed;
    bottom: 70px;
    right: 170px;
    cursor: pointer;
    transform: scale(1);
    transition: transform .2s;
}

#up-arrow-icon {
    height: 60px;
    width: 60px;
    transform: rotate(-90deg);
}

@media screen and (max-width: 775px) {
    .mobile-layout {
        display: flex;
    }

    .medications .alphabet {
        flex-direction: column;
        padding: 0 .1em;
        position: -webkit-sticky;
        position: sticky;
        top: 45px;
        width: -webkit-min-content;
        width: -moz-min-content;
        width: min-content;
        height: 650px;
        font-size: .8em;
    }

    .letter-container {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .letter-list {
        padding: 0;
    }

    .arrow-wrapper {
        bottom: 35px;
        right: 15px;
    }

    .arrow-wrapper-scrolled {
        bottom: 35px;
        right: 15px;
    }

    #up-arrow-icon {
        height: 50px;
        width: 50px;
    }
}
#iv {
    width: 30%;
    float: right;
    margin: 1em;
    border-radius: 5px;
    -webkit-animation: pop-in 1s;
            animation: pop-in 1s;
}


@media screen and (max-width: 775px) {
    #iv {
        float: none;
        width: 75%;
        display: block;
        margin: 0 auto;
    }
}

.infusion-center li {
    margin-bottom: 10px;
}

:root {
  --light-blue: #00a7e2;
  --dark-blue: #1b3360;
}

#main {
  font-family: 'Open Sans', sans-serif;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: #00a7e2;
  color: var(--light-blue);
}

h2 {
  font-size: 2.3em;
  font-weight: 600;
  line-height: 1.4em;
}

h3 {
  font-size: 1.7em;
  font-weight: 600;
  line-height: 1.4em;
}

address {
  font-style: normal;
}

p, ul {
  font-size: 1.2em;
  font-weight: 400;
  color: rgb(73, 71, 71);
}

/* Subpage Template */
.subpage {
  padding: 0 8em;
  background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), 100%/cover url(/static/media/wood-fade.47e01974.jpg);
}

.subpage-content {
  min-height: 100vh;
  padding: 1em 4em 1em 4em;
  background-color: white;
}

.subpage-content header {
  text-align: center;
  margin-bottom: 2em;
}

.subpage-content h1 {
  font-size: 2.6em;
  margin: 0;
  padding: .5em 0;
  text-align: center;
  font-weight: 300;
  color: #1b3360;
  color: var(--dark-blue);
}

.subpage-content h2 {
  margin: 0 0 1em 0;
  font-weight: 400;
  font-size: 2.6em;
  color: #00a7e2;
  color: var(--light-blue);
}

.subpage-content h3 {
  margin: 0;
  padding: 1em 0;
  font-weight: 300;
  font-size: 2em;
}

.subpage-content p, ul {
  font-weight: 400;
  font-size: 1.3em;
  line-height: 1.5em;
}

.cursive {
  font-family: 'Dancing Script', cursive;
}


@media screen and (min-width: 895px) {

  .subpage-content h1 {
    display: flex;
  }

  .subpage-content h1::before,
  .subpage-content h1::after{
  content:'';
  flex: 1 1;
  border-bottom: 2px solid darkgrey;
  margin: auto;
}

}

@media screen and (max-width: 1130px) {

  html {
    font-size: 90%;
  }
}

@media screen and (max-width: 895px) {
 
  .subpage {
    padding: 0;
  }

  .subpage-content {
    padding: 1em;
  }

  .subpage-content h1 {
    font-size: 2.5em;
  }

  .subpage-content h2 {
    text-align: center;
  }
}

/* Animations */
@-webkit-keyframes pop-in {
  0% {
      transform: scale(.99);
      opacity: 0;
  }
  100% {
      transform: scale(1);
      opacity: 1;
  }
}
@keyframes pop-in {
  0% {
      transform: scale(.99);
      opacity: 0;
  }
  100% {
      transform: scale(1);
      opacity: 1;
  }
}
