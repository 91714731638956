#research-icon {
    width: 80%;
    height: 85%;
    transform: scale(-1, 1);
}

.home-quest {
    padding: 2em 8em 4em 6em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #F8F9FA;  
}

.quest-img-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.quest-text {
    display: flex;
    flex-direction: column;
    color: rgb(73, 71, 71);
}

.quest-title {
    margin: .7em 0;
    color: var(--dark-blue);
    text-align: center;
}

.quest-days, .quest-hours {
    margin: .7em 0;
    font-weight: 400;
    font-size: 1.3em;
    text-align: center;
    
}

.quest-description {
    text-align: center;
}

@media screen and (max-width: 914px) {
    .home-quest {
        display: flex;
        flex-direction: column;
        padding: 1em;
    }

    .quest-description {
        padding: 0 6em;
        font-size: 1.3em;
    }

    .quest-img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1em 0; 
    }

    #research-icon {
        margin: 1em 0;
        width: 40vw;
        transform: scale(-1, 1);
    }
}

@media screen and (max-width: 600px) {
    .quest-days, .quest-hours {
        font-size: 1.4em;
    }

    .quest-days {
        font-size: 1.2em;
    }

    .quest-description {
        padding: 0 2em;
    }
}