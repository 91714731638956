.home-office-photos {
    padding: 6em 10em;
}

.photos-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 250px);
    grid-template-areas: 
        "g i a"
        "g h f"
        "b e f"
    ;
    gap: 8px;
}

#exam-room-wall {
    grid-area: a;
}

#infusion-room {
    grid-area: b;
}

#exam-room {
    grid-area: e;
    object-position: 20%;
}
#infusion-chairs-2 {
    grid-area: f;
    object-position: 95%;
}
#hallway {
    grid-area: g
}

#exterior-logo {
    grid-area: h
}

#lobby {
    grid-area: i
}

.photos-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
}

@media screen and (max-width: 900px) {
    .home-office-photos {
        padding: 1em;
    }

    .photos-container {
        display: flex;
        flex-direction: column;
    }

    .photos-container img {
        margin-top: .5em;
    }
}